<template>
    <div class="al-content">


        <div>
            <div class="panel panel-blur with-scroll animated zoomIn" style="max-width:800px">
                <div class="panel-heading clearfix">
                    <h3 class="panel-title">{{formTitle}}</h3>
                </div>
                <div class="panel-body">

                    <div>
                        <form class="form-horizontal" id="configForm">

                            <div class="form-group row"
                                 v-for="value,key in configs" :key="key" v-show="key!='HamperDefaultBoxes'&&key!='HamperDefaultItems'">
                                <label for="key" class="col-sm-3 control-label">{{key}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control mb-1"
                                           :name="key"
                                           :value="value"
                                           :disabled="checkAcl('Edit')?null:'disabled'" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-3 control-label"></label>
                                <div class="col-sm-">
                                    <button class="btn btn-primary" @click.prevent="save">{{btnSaveText}}</button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    select option {
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
</style>
<script>
    import $ from 'jquery'

    import { mapState, mapActions } from 'vuex'
    export default {
        name: `Config`,
        props: {

        },
        data: function () {
            return {
                configs: [],
                configsKey:[],
                btnSaveText: 'Save',
                formTitle: 'System Config',
            }
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,//always check this
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes("Config/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            async start() {
                //load all config
                this.loading(true);
                const rs = await this.call({ Action: 'Config/GetAll' })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    //no permission
                    if (rs.Data == "nopermission") {
                        window.history.back();
                    }
                } else {

                    this.configs = rs.Data;
                    console.log(rs.Data);
                    this.configsKey = Object.keys(this.configs);
                }


            },


            async save() {
                //validate

                var unindexed_array = $("#configForm").serializeArray();
                var indexed_array = {};

                $.map(unindexed_array, function (n) {
                    indexed_array[n['name']] = n['value'];
                });

                this.loading(true);
                const rs = await this.call({ Action: 'Config/Save', Object: { Configs: JSON.stringify(indexed_array) } })
                this.loading(false);

                if (rs.Error != "") {
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    this.showToast({ isShow: true, title: '', content: `${this.btnSaveText} Config successfull`, type: 'success' });
                }
            },


        },
        mounted() {
            //if already login then start,
            //else will watch isCheckedLogin to catch its change
            //to call start function
            //Please do not remove this
            if (this.isCheckedLogin) this.start();
            //========================

        },
        watch: {
            //catch status from not login to logged in
            //to call start function.
            //Apply for reload page issue
            //Please do not remove this
            isCheckedLogin(newVal) {
                if (newVal) {
                    this.start();
                }
            }
            //========================
        }
    };
</script>